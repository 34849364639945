<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary mb-0">
            <div class="text-primary p-4">
              <h5 class="text-primary">Xác minh số điện thoại</h5>
            </div>
          </div>
          <div class="card-body">
            <b-form class="mb-2" @submit.prevent="handleSubmit">
              <div class="mt-2 mb-2">
                <span>Nhập số điện thoại của bạn :</span>
              </div>
              <b-form-group>
                <b-form-input type="text" v-model="phone" placeholder="Nhập số điện thoại"></b-form-input>
              </b-form-group>
              <div v-if="showVerifyPhoneMessage" class="alert alert-danger">
                {{ verifyPhoneMessage }}
              </div>
              <div class="mt-4">
                <div id="recaptcha-container"></div>
                <b-button type="submit" variant="primary" class="btn-block">
                  Tiếp tục
                </b-button>
              </div>
            </b-form>
            <div class="text-center py-4 auth-method">
              <span class="text-primary">Xác thực bằng cách khác</span>
              <div style="font-size: 30px">
                <img style="cursor: pointer" src="../../assets/icons/mail (1).svg" @click="gotoAuthEmail" />
              </div>
              <span class="text-primary" style="font-size: 0.8rem">Xác thực bằng Email</span>
            </div>
          </div>
          <div class="logo text-center" @click="gotoLogin">
            <span class="text-primary" style="cursor: pointer">
              <img src="../../assets/icons/arrow-left (1).svg" />
              Quay lại</span>
          </div>
        </div>

        <div class="mt-5 text-center">
          <p>© Bản quyền thuộc Hợp tác xã Nông nghiệp số</p>
        </div>
        <b-modal v-model="otpModal" id="modal-prevent-closing" title="Đổi mật khẩu" hide-footer>
          <b-form>
            <b-form-group label="Mã xác thực">
              <b-form-input id="otp-input" v-model="form.otp"></b-form-input>
            </b-form-group>
            <b-form-group label="Nhập mật khẩu mới" id="form-new-password">
              <b-form-input id="new-pass-input" v-model="form.newPass" :type="isShowNewPass ? 'text' : 'password'">
              </b-form-input>
              <img v-if="isShowNewPass" class="hide-icon" src="../../assets/icons/eye.svg" @click="toggleShowNewPassword" />
              <i v-else class="hide-icon bx bx-hide" @click="toggleShowNewPassword"></i>
            </b-form-group>
            <b-form-group label="Xác nhận mật khẩu mới" id="form-confirm-password">
              <b-form-input id="confirm-pass-input" v-model="form.confirmPass" :type="isShowConfirmPass ? 'text' : 'password'">
              </b-form-input>
              <img v-if="isShowConfirmPass" class="hide-icon" src="../../assets/icons/eye.svg" @click="toggleShowConfirmPassword" />
              <i  v-else class="hide-icon bx bx-hide" @click="toggleShowConfirmPassword"></i>
            </b-form-group>
            <div v-if="showVerifyMessage" class="alert alert-danger">
              {{ verifyMessage }}
            </div>
            <b-button @click.prevent="verifyOtp" type="submit" variant="primary" class="btn-block"
              v-b-modal.modal-prevent-closing>
              Xác nhận
            </b-button>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { httpClient } from "../../_utils/httpClient";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { router } from "../../router";
export default {
  data() {
    return {
      showVerifyMessage: false,
      verifyMessage: "",
      showVerifyPhoneMessage: false,
      verifyPhoneMessage: "",
      otpModal: false,
      appVerifier: "",
      result: "",
      form: {
        otp: "",
        newPass: "",
        confirmPass: "",
      },
      phone: "",
      formCheckPhone: {
        code: "",
        message: "",
      },
      isShowConfirmPass: false,
      isShowNewPass : false
    };
  },
  watch: {},
  methods: {
    ...mapActions("account", ["recovery"]),
    async handleSubmit() {
      await this.getInfoPhone();
      if (this.checkPhone()) {
        this.sendOtp();
      } else {
        return;
      }
    },
    async sendOtp() {
      this.showVerifyMessage = false;
      this.verifyMessage = "";
      delete window.confirmationResult;
      let countryCode = "+84";
      let phoneNumber = countryCode + this.phone.slice(1);
      let appVerifier = this.appVerifier;
      try {
        window.confirmationResult = await firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier);
        this.showVerifyMessage = false;
        this.verifyMessage = ``;
        this.form.otp = ``;
        Swal.fire({
          title: 'Mã xác thực (6 số) đã được gửi về số điện thoại của bạn !',
          preConfirm: () => { this.otpModal = true; }
        })

      } catch (e) {
        console.log("e", e);
      }
    },
    async getInfoPhone() {
      await httpClient
        .post(`auth/exists`, {
          id: this.phone,
        })
        .then((data) => {
          this.formCheckPhone = data.data;
        });
    },
    checkPhone() {
      let regexPhone = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
      this.showVerifyPhoneMessage = false;
      this.verifyPhoneMessage = "";
      if (this.phone == "") {
        this.showVerifyPhoneMessage = true;
        this.verifyPhoneMessage = `Số điện thoại không được trống !`;
        return false;
      }
      if (this.phone.length !== 10 || !regexPhone.test(this.phone)) {
        this.showVerifyPhoneMessage = true;
        this.verifyPhoneMessage = `Số điện thoại không hợp lệ.`;
        return false;
      }
      if (this.formCheckPhone.code !== 2) {
        this.showVerifyPhoneMessage = true;
        this.verifyPhoneMessage = this.formCheckPhone.message;
        return false;
      }
      return true;
    },
    async verifyOtp() {
      this.showVerifyMessage = false;
      this.verifyMessage = "";
      if (!this.validator()) {
        return;
      } else {
        let code = this.form.otp;
        try {
          let result = await window.confirmationResult.confirm(code);
          let user = result.user;
          let token = await user.getIdToken();
          await this.changePassword(token);
        } catch (e) {
          this.showVerifyMessage = true;
          this.verifyMessage = `Sai mã xác thực`;
          return false;
        }
      }
    },
    async changePassword(token) {
      let body = {
        id: this.phone,
        password: this.form.newPass,
        token,
      };
      httpClient.post(`/auth/forgot`, body).then((response) => {
        if (response.data.code === 0) {
          Swal.fire({
            title: "Đổi mật khẩu thành công",
            icon: "success",
            preConfirm: () => {
              router.push("/", () => { });
            },
          });
        }
      });
    },
    hasWhiteSpace(s) {
      return s.indexOf(" ") >= 0;
    },
    validator() {
      let formPass = {
        ...this.form,
      };
      let mappingErrorName = {
        otp: "Mã xác thực",
        newPass: "Mật khẩu mới",
        confirmPass: "Xác nhận mật khẩu mới",
      };

      for (let key in formPass) {
        if (formPass[key] !== null && formPass[key].length < 1) {
          this.showVerifyMessage = true;
          this.verifyMessage = mappingErrorName[key] + " không được trống";
          return false;
        }
      }
      if (formPass.otp.length !== 6) {
        this.showVerifyMessage = true;
        this.verifyMessage = `Mã xác thực phải có 6 số`;
        return false;
      }

      if (formPass.newPass !== formPass.confirmPass) {
        this.showVerifyMessage = true;
        this.verifyMessage = "Mật khẩu không trùng khớp";
        return false;
      }
      if (formPass.newPass.length < 6) {
        this.showVerifyMessage = true;
        this.verifyMessage = "Mật khẩu có ít nhất 6 kí tự";
        return false;
      }
      if (this.hasWhiteSpace(formPass.newPass)) {
        this.showVerifyMessage = true;
        this.verifyMessage = "Mật khẩu không được có khoảng trắng !";
        return false;
      }
      return true;
    },
    toggleShowConfirmPassword() {
      this.isShowConfirmPass = !this.isShowConfirmPass
    },
    toggleShowNewPassword() {
      this.isShowNewPass = !this.isShowNewPass;
    },
    gotoAuthEmail() {
      router.push({ name: "auth.email" });
    },
    gotoLogin() {
      router.push({ name: "auth.login" });
    },
    initReCaptcha() {
      setTimeout(() => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function (response) {
              console.log("reCAPTCHA :", response);
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
            },
            "expired-callback": function () {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            },
          }
        );
        //
        window.recaptchaVerifier.render().then((widgetId) => {
          window.recaptchaWidgetId = widgetId;
        });
        this.appVerifier = window.recaptchaVerifier;
      }, 1000);
    },
  },
  created() {
    this.initReCaptcha();
  },
};
</script>
<style scoped>
.auth-method {
  position: relative;
}

.auth-method::before {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  background-color: #556ee6;
  top: 14px;
  left: 18px;
}

.auth-method::after {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  background-color: #556ee6;
  bottom: 15px;
  left: 18px;
}

.hide-icon {
  position: absolute;
  right: 12px;
  top: 34%;
  cursor: pointer;
  font-size: 18px;
}

#form-confirm-password,
#form-new-password {
  position: relative;
}
</style>
